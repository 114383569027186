import { useStaticQuery, graphql } from "gatsby";
import { defaultArea } from "@constants";
import _ from "lodash"

export const getPriceStr = ( price ) => {
  let priceStr = '';
  if (price.min && price.max) {
      priceStr = " between " + `£` + addCommas(price.min) + " and " + `£` + addCommas(price.max);
  } else if (price.max) {
      priceStr = " under " + `£` + addCommas(price.max);
  } else if (price.min) {
      priceStr = " over " + `£` + addCommas(price.min);
  }
  return priceStr;
}

function addCommas(num) {
  var str = num.toString().split('.');
  if (str[0].length >= 4) {
      // add comma every 3 digits before decimal
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  /* Optional formating for decimal places
  if (str[1] && str[1].length >= 4) {
      // add space every 3 digits after decimal
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }*/
  return str.join('.');
}

export const ACTION_TYPES = {
  sales: " for sale in ",
  lettings: " to rent in ",
  salessold: " Sold ",
  lettingslet: " Let "

}

export const ACTION_DESC_TYPES = {
  sales: " for sale ",
  lettings: " to rent ",
}

export const pTypeBase = {
  sales: "/property/for-sale/",
  lettings: "/property/to-rent/",
}

export const buildingTypes = {
    "apartment" : "apartments",
    "detached-house" : "detached houses",
    "semi-detached-house": "semi detached houses",
    "terraced-house": "terraced houses",
    "bungalow": "bungalows",
    "other" : "other",
    "flat": "flats",
    "townhouse": "townhouses"
}

export const toTitleCase = (text) => {
  let _text = text || '';
  if ( _text ) {
    _text = _text.trim().split(' ');
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text.replaceAll(' And ', ' and ');
}

export const makeLowerCaseExceptArea = (text) => {
  if (text.match(/for sale/)) {
     let data = text.split('for sale');
     return data[0].toLowerCase() + ' for sale ' + data[1];
  }
  if (text.match(/to rent/)) {
     let data = text.split('to rent');
     return data[0].toLowerCase() + ' to rent ' + data[1];
  }
  return text;
}

// TODO: hard coded details to be removed/replaced with common variables
export const parseSearchUrl = (pType, location) => {

  //console.log("location", pType, location)
  if (location) {
      var query = '';
      let pathUri_main = location.split(pTypeBase[pType].slice(0, -1))
      var areaVal = ''
      var bedVal = ''
      var minpriceVal = ''
      var maxpriceVal = ''
      var typeVal = ''
      var mustIncludeVal = ''
      var radiusVal = ''
      var sortVal = ''
      
      if( pathUri_main[0] ) { // following could be regexp
        let pathUri = pathUri_main[0].split('/');

        //lets loop
        for(let vi = 1; vi <= pathUri.length; vi++ ) {
          // check for area
          if( typeof pathUri[vi] === "undefined" ) {continue;}
          // for-sale
          if ( pathUri[vi].indexOf("property-for-sale") >= 0 ) {
            // its area
            pType = 'sales';
          }
          if ( pathUri[vi].indexOf("property-to-rent") >= 0 ) {
            // its area
            pType = 'lettings';
          }
          if ( pathUri[vi].indexOf("in-") == 0 ) {
            // its area
            areaVal = pathUri[vi].replace("in-","").replaceAll("-"," ");
          }
          if ( pathUri[vi].indexOf("-and-more-") >= 0 ) {
            // its bedrooms
            bedVal = pathUri[vi].replace("-and-more-bedrooms","")
          }
          if ( pathUri[vi].indexOf("studio") >= 0 ) {
            // its studio bedroom
            bedVal = 0
          }
          if ( pathUri[vi].indexOf("between-") >= 0 ||
               pathUri[vi].indexOf("over-") >= 0 ||
               pathUri[vi].indexOf("under-") >= 0
             ) {
            // its price
            let priceFilt1 = pathUri[vi].split('over-');
            if( priceFilt1[1] ) {
              minpriceVal = priceFilt1[1]
            }
            let priceFilt2 = pathUri[vi].split('under-');
            if( priceFilt2[1] ) {
              maxpriceVal = priceFilt2[1]
            }
            let priceFilt3 = pathUri[vi].split('between-');
            if( priceFilt3[1] ) {
              let priceFilt4 = priceFilt3[1].split('-and-');
              minpriceVal = priceFilt4[0]
              maxpriceVal = priceFilt4[1]
            }
          }
          // Must Include
          if (pathUri[vi].indexOf("with-") >= 0) {
            var mustIncludeArrfilt = pathUri[vi].replace("with-", "")
            mustIncludeVal = mustIncludeArrfilt.split("-and-")
          }
          let priceFilt5 = pathUri[vi].split('type-');
          if( priceFilt5[1] ) {
            typeVal = priceFilt5[1]
          }

          // Radius
          if (pathUri[vi].indexOf("radius-") >= 0) {
              radiusVal = pathUri[vi].replace("radius-", "").replace("-miles")
          }

          // sort by
          if (pathUri[vi].indexOf("sortby-") >= 0) {
            sortVal = pathUri[vi].replace("sortby-", "")
          }
        }
      }

    // If no area send default to set default loaction
    // We could send defaut location
    if (!areaVal || areaVal === 'undefined') {
        areaVal = defaultArea.slug; // TODO
    }

    var areaAlias = areaVal.replaceAll(' ', '-');

    return {
      pType: pType,
      areas: areaVal,
      areas_alias: areaAlias,
      bedrooms: bedVal,
      type: typeVal,
      radius: ((areaVal.replaceAll(' ', '-')) ===  defaultArea.slug && (!radiusVal || radiusVal === 3)) ? 0 : parseInt(radiusVal),
      mustInclude: mustIncludeVal,
      sortVal: sortVal,
      price: {
        min: minpriceVal,
        max: maxpriceVal,
      },
      extra: {
        bedPlus: false,
        areasTitleCase: true
      }
    }
  }
}

// TODO: hard coded details to be removed/replaced with common variables
export const savedSearchParams = (params) => {
  const {areas, bedrooms, pType, type, price, mustInclude} = params;

  return {
    department: "residential",
    search_type: pType,
    areas: areas,
    bedroom: bedrooms,
    minPrice:  price.min,
    maxPrice:  price.max,
    building: type,
    must_Include: mustInclude,
  }
}


// TODO: hard coded details to be removed/replaced with common variables
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const propertyH1 = (params) => {
  const {areas, bedrooms, pType, type, price, extra, mustInclude, radius} = params;
   let h1 = "";

  if (params?.ptypetag === 'sold' || params?.ptypetag === 'let') {
        let building = "properties";
        let bedroomStr = ``;
        let actionStr = ``;
        let areaStr = ``;
        let priceStr = ``;
        let featureStr = ``;
        let radiusStr = ``;

        if (bedrooms)
          building = "properties";

        if (bedrooms) {
          let bedPlus = extra.bedPlus ? '+' : '+';
          bedroomStr = ` with ${bedrooms}${bedPlus} bedroom `;
        }

        if (type) {
          building = ` ${_.get(buildingTypes, type, type)} `;
        } else {
          building = `properties `;
        }

        if (!bedrooms)
            building = capitalize(building);

        // if (building)
        //     building = `with ${building}`
        //
        // h1 += toTitleCase(building);
        if (params?.ptypetag && (params?.ptypetag === 'sold' || params?.ptypetag === 'let'))
            actionStr = ACTION_TYPES[`${pType + params?.ptypetag}`] || '';
        else
            actionStr = ACTION_TYPES[pType] || '';

        if (areas) {
          let _areas = areas;
          _areas = _areas.replaceAll("-"," ");
          _areas = decodeURIComponent(_areas);
          _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
          areaStr = _areas.replace("north","North").replace("road","Road")
        }

        if ( price && (price.min || price.max) )  {
          priceStr = `at ${getPriceStr(price)}`;
        }

        // if ( mustInclude && mustInclude ) {
        //   featureStr = " with "+mustInclude.join('-and-')
        // }
        //
        // if (radius) {
        //     radiusStr = ` within ${radius} miles `
        // }

        if (!bedroomStr && !priceStr && areaStr === defaultArea.name) {
            if (!areaStr) {
                areaStr = defaultArea.name
            }
            h1 = `Our Collection of ${actionStr} properties in ${areaStr}`
        } else {
            h1 = `${actionStr} ${building} ${bedroomStr} in ${areaStr} ${priceStr}`
        }
        // [action] [property type] with [bedroom] in [address] at [price offered]
  } else {
      let building = "";

      if ('Commercial' === pType) { // TODO
        // Commercial Property [action] in [default search area] for Sale and Rent
        building = "Commercial ";

        if (type)
          building += ` ${type} `;
        else
          building += ` Properties `;

        h1 += `${building} in `;

        if (areas) {
          h1 += areas;
        }

        h1 += ` for sale and rent`;

      } else {
        let building = "properties";
        if (bedrooms)
          building = "properties";

        if (bedrooms) {
          let bedPlus = extra.bedPlus ? '+' : '+';
          h1 += bedrooms + bedPlus + " Bedroom ";
        }

        if (type) {
           building = `${_.get(buildingTypes, type, type)} `;
        } else {
          building = ` properties `;
        }

        if (!bedrooms)
            building = toTitleCase(building);

        h1 += (building);
        if (params?.ptypetag && (params?.ptypetag === 'sold' || params?.ptypetag === 'let'))
            h1 += ACTION_TYPES[`${pType + params?.ptypetag}`];
        else
            h1 += ACTION_TYPES[pType];

        if (areas) {
          let _areas = areas;
          _areas = _areas.replaceAll("-"," ");
          _areas = decodeURIComponent(_areas);
          _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
          h1 += _areas.replace("north","North").replace("road","Road")
        }

        if ( price && (price.min || price.max) )  {
          h1 += getPriceStr(price);
        }

        if ( mustInclude && mustInclude ) {
          h1 += " with "+mustInclude.join('-and-')
        }

        if (radius) {
            h1 += ` within ${radius} miles `
        }
      }
  }

  return h1;
}

export const handleBackToListing = (props) => {
    if (window.history.length) {
        window.history.go(-1);
        return;
    }
    let statusStr = 'for-sale';
    let areaStr = `in-${defaultArea.slug}`;
    if (props?.search_type === 'lettings')
        statusStr = 'to-rent';
    if (props?.area)
        areaStr = `in-${props.area}`;

    window.location.pathname = `property-${statusStr}/${areaStr}`;

}

const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
const regex = new RegExp(expression);

export const isExternalUrl = (str) => {
    return (str.match(regex))
}

import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import CookieConsent from "../CookieConsent/CookieConsent";
import parse from 'html-react-parser';
import "./Footer.scss";
import SiteLogo from "../../images/site-logo.png"
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import GenerateLink from "../common/site/generate-link"
import PopularSearchResults from "../popular-search/search-results"
import PopularSearchDetails from "../popular-search/property-details"
import PopularSearchArea from "../popular-search/area-office-details"

const Footer = (props) => {

  const [showAccordion, setShowAccordion] = useState(null);

  const footerShow = () => {
    // setShow(!show);
    // setActiveId(1)

  }

  const [activeId, setActiveId] = useState(1);
  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null)
    } else {
      setActiveId(id)
    }
  }

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menusFooter {
        Add_Menu {
          Label
          Link {
            id
          }
          Add_Sub_Menu {
            Submenu_Label
            Submenu_Link {
              URL
              id
            }
            Submenu_Custom_Link
          }
        }
      }

      siteConfig {
        Facebook_Link
        Instagram_Link
        Twitter_Link
        Youtube_Link
        Linkedin_Link
        Popular_Search_Static_Common
        Popular_Search_Static_Rent
        Popular_Search_Static_Sell
      }
      globalModule {
        Footer_Content
        Footer_CTA_2_Label
        Footer_CTA_2_Link {
          URL
          id
        }
        Footer_CTA_1_Label
        Footer_CTA_1_Link {
          URL
          id
        }
      }
    }
  }
`);

  const menus = data.glstrapi.menusFooter;
  const social = data.glstrapi.siteConfig;
  const globalModule = data.glstrapi.globalModule;
  const year = new Date().getFullYear(); 
  return (
    <React.Fragment>
      <footer className="footer">
        <Container>
          <Row>
            <Col sm={12}>
              <div className="footer-wrapper row">
                <Col sm={6}>
                  {globalModule.Footer_Content &&
                    <div className="footer-content">
                      {parse(globalModule.Footer_Content)}
                    </div>
                  }
                  <div className="footer-btns">
                    {globalModule.Footer_CTA_1_Label && globalModule.Footer_CTA_1_Link &&
                      <GenerateLink link={globalModule.Footer_CTA_1_Link} className="btn">{globalModule.Footer_CTA_1_Label}</GenerateLink>
                    }
                    {globalModule.Footer_CTA_2_Label && globalModule.Footer_CTA_2_Link &&
                      <GenerateLink link={globalModule.Footer_CTA_2_Link} className="btn">{globalModule.Footer_CTA_2_Label}</GenerateLink>
                    }

                  </div>
                </Col>
                <Col sm={6}>
                  <div className="footer-nav">
                    {menus.Add_Menu.length > 0 &&
                      <div className="menu-wrapper d-none d-lg-flex">
                        {menus && menus.Add_Menu.map((menu, index, active) => {
                          return (
                            <div className="menus">
                              <a href="javascript:void(0);" className="menu-header">{menu.Label}</a>
                              {menu.Add_Sub_Menu.length > 0 &&
                                <ul className="submenu">
                                  {menu && menu.Add_Sub_Menu.map((submenu, index, active) => {
                                    if(submenu.Submenu_Custom_Link===null) {
                                    return (
                                      <li>
                                        <GenerateLink link={submenu.Submenu_Link}>{submenu.Submenu_Label}</GenerateLink>
                                      </li>
                                    )
                                  }else{
                                    return (
                                      <li>
                                        <a href={submenu.Submenu_Custom_Link}>{submenu.Submenu_Label}</a>
                                      </li>
                                    )
                                  }
                                  })}
                                </ul>
                              }
                            </div>
                          )
                        })}
                      </div>
                    }
                  </div>
                </Col>
              </div>
              <div className="secondary-footer"> 
                {menus.Add_Menu.length > 0 &&
                  <div className="menu-wrapper mobile d-block d-lg-none">
                    {menus && menus.Add_Menu.map((menu, index, active) => {
                      return (
                        <div className="menus">
                          <a href="javascript:void(0)"  onClick={() => {
                              if(showAccordion === index.toString()){
                                setShowAccordion(null)
                              }else{
                                setShowAccordion(index.toString())
                              } 
                            }}>{menu.Label}
                          <i className={index == showAccordion ? "icon-down-arrow open" : "icon-down-arrow"}
                            onClick={() => {
                              if(showAccordion === index.toString()){
                                setShowAccordion(null)
                              }else{
                                setShowAccordion(index.toString())
                              } 
                            }}
                          ></i>
                          </a>
                          {menu.Add_Sub_Menu.length > 0 &&
                            <ul className={`submenu ${index == showAccordion ? "d-block" : "d-none"}`}>
                              {menu && menu.Add_Sub_Menu.map((submenu, index, active) => {
                                  if(submenu.Submenu_Custom_Link===null) {
                                      return (
                                        <li>
                                        <GenerateLink link={submenu.Submenu_Link}>{submenu.Submenu_Label}</GenerateLink>
                                        </li>
                                      )
                                  }else{
                                      return (
                                        <li>
                                        <a href={submenu.Submenu_Custom_Link}>{submenu.Submenu_Label}</a>
                                        </li>
                                      )
                                  }
                              })}
                            </ul>
                          }
                        </div>
                      )
                    })}
                  </div>
                }
                <h2 className="h4 popular-search"
                  onClick={() => {
                    if(showAccordion === "popular"){
                      setShowAccordion(null)
                    }else{
                      setShowAccordion("popular")
                    } 
                  }}>
                  Popular Searches
                  <Link>
                    <i className={showAccordion == "popular" ? "icon-down-arrow open" : "icon-down-arrow"}>
                    </i>
                  </Link>
                </h2>
                {showAccordion == "popular" ? (
                  <div className="popular-search">
                    {props.popular_search == 'Sell' ?
                      <>
                        {parse(social.Popular_Search_Static_Sell)}
                      </> : props.popular_search == 'Rent' ?
                        <>
                          {parse(social.Popular_Search_Static_Rent)}
                        </> : props.popular_search == "results" ?
                          <>
                            <PopularSearchResults searchtype={props.searchtype} searchBedroomfield={props.searchBedroomfield} searchPtype={props.searchPtype} Searcharea={props.Searcharea} />
                          </> : props.popular_search == "property_details" ?
                            <>
                              <PopularSearchDetails {...props} />
                            </> : props.popular_search == "area" ?
                              <>
                                <PopularSearchArea propertydetails={props.propertydetails} area_name={props.area_name} />
                              </> :
                              <>
                                {parse(social.Popular_Search_Static_Common)}
                              </>
                    }
                  </div>
                ) : null}
              </div>
              <div className="policies">
                <div className="term">
                  <Link to="/complaints-procedure" className="policy">
                    Complaints Procedure
                  </Link>
                  <Link to="/privacy-policy" className="policy">
                    Privacy Policy
                  </Link>
                  <Link to="/terms-and-conditions" className="policy">Terms & Conditions</Link>
                  <a href="javascript:void(0);" id="open_preferences_center" class="me-0">Update Cookies Preferences</a>
                  <div className="site-info">
                    <span className="site-link">
                      © {year} . All rights reserved.{" "}
                      Site by
                      <a href="https://starberry.tv/" target="_blank" className="site-logo">
                        <img src={SiteLogo} alt="Starberry logo" />
                      </a>
                      <a href="https://starberry.tv/" target="_blank">Starberry</a>
                    </span>
                  </div>
                </div>
                <ul className="social-media">
                  {social.Facebook_Link &&
                    <li>
                      <a href={social.Facebook_Link} target="_blank" >
                        <i className="icon-fb"></i>
                      </a>
                    </li>
                  }
                  {social.Instagram_Link &&
                    <li>
                      <a href={social.Instagram_Link} target="_blank" >
                        <i className="icon-insta"></i>
                      </a>
                    </li>
                  }
                  {social.Linkedin_Link &&
                    <li>
                      <a href={social.Linkedin_Link} target="_blank" >
                        <i className="icon-linkedin"></i>
                      </a>
                    </li>
                  }
                  {social.Youtube_Link &&
                    <li>
                      <a href={social.Youtube_Link} target="_blank" >
                        <i className="icon-youtube"></i>
                      </a>
                    </li>
                  }
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>


      <CookieConsent />

    </React.Fragment>
  );
};

export default Footer;

import React, { useState, useEffect, PropTypes } from "react"
import Autosuggest from 'react-autosuggest';
import GlobalContext from '../../context/GlobalContext';
import _ from "lodash"
import axios from 'axios';
import './assets/styles/_index.scss';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => `${suggestion.name} ${(suggestion.location) ? `(${suggestion.location})` : ''}`;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {`${suggestion.name} ${(suggestion.location) ? `(${suggestion.location})` : ''}`}
  </div>
);




class SearchResultBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props?.value,
      suggestions: [],
      areas: props?.areaList || [],
      aliasVal: "",
      isChages: false
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      aliasVal: newValue,
      isChages: true
    });
    if (this?.props?.setValue) {
        this.props.setValue(newValue)
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  suggestionSelected = (e, { suggestion }) => {

    if (suggestion) {
      this.setState({
        value: `${suggestion.name} ${(suggestion.location) ? `(${suggestion.location})` : ''}`,
        aliasVal: suggestion.name,
      });
      if (this?.props?.setValue) {
          this.props.setValue(suggestion.name)
      }
    }
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.state.areas.filter(area =>
      area.name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };


  componentDidMount() {
    if (!this.state.areas.length && this.props.areaList.length) {
        this.setState({ areas: this.props.areaList})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.areas.length && this.props.areaList.length) {
      this.setState({ areas: this.props.areaList})
    }
  }

  render() {
    const { value, suggestions, isChages, aliasVal } = this.state;

    // Autosuggest will pass through all these props to the input.
    var setVal = value;
    var setAliasVal = aliasVal;
    if (_.isEmpty(setVal) && !isChages && !_.isEmpty(this.props.areaVal)) {
      setVal = this.props.areaVal;
      setAliasVal = this.props.areaVal
    }

    const inputProps = {
      placeholder: this.props.placeHolder,
      value: setVal,
      onChange: this.onChange,
      className: 'form-control'
    };

    // Finally, render it!
    return (
        <div className="filter-search form-group react-autosuggest__container">
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={this.suggestionSelected}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                // alwaysRenderSuggestions={true}
            />
            <a className="search-icon">
                <i class="icon-search-white"></i>
                <i class="icon-map-search-pin"></i>
            </a>
            <input type="hidden" className="searchVal" value={value || aliasVal}/>
        </div>
    );
  }
}

const SearchResultBoxWrap  = (props) => {
    return(
        <GlobalContext.Consumer>
        {globalProps => {
            return (
                <SearchResultBox {...props} {...globalProps} />
            )
        }}
        </GlobalContext.Consumer>
    )
}

export default SearchResultBoxWrap;

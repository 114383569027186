import React from "react";
import { Link } from "@StarberryUtils";

import { GetURL } from "./functions";
import { isExternalUrl } from "./utils";


const GenerateLink =(props,{children})=> {
	let url = ''
	if (props.link) {
		url = GetURL(props.link.id)
	}

	//checking investmention button clicked means this url will work

	if(props.label === 'Get in touch today'){
		return(
			<Link to={`/${url}?type=investment`}  className={props.class || props?.className}>{props.children}</Link>
		)
	}
	// check for external URL. If full path available consider as external url and make it open in new tab

	if (isExternalUrl(url)) {
		return(
			<a href={`${url}`} target="_blank" className={props.class || props?.className}>{props.children}</a>
		)
	} else {
		return(
			<Link to={`/${url}`}  className={props.class || props?.className}>{props.children}</Link>
		)
	}
}

export default GenerateLink;

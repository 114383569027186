import React, { useState,useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Row, Col } from 'react-bootstrap';
import { get } from 'lodash';
import $ from 'jquery'
import { buildingTypes } from '../common/site/utils';

import { BOOK_A_VALUATION_PAGE_URL, CONTACT_PAGE_URL, ABOUT_PAGE_URL, PROPERTY_SERVICES_PAGE_URL, AREA_GUIDES_PAGE_URL} from '../common/site/constants'


const PopularSearchDynamic = (props) => {
    console.log("fffffff => ", props);
  //Get values from refine search
  const [ptype,setPtype] = useState(false)
  const [searchbed,setsearchbed] = useState(false)

  var searchaction = ''
  var URL = ''
  var ptypeURL = ''
  var bedURL = ''
  var bedtypeText= ''
  var area = 'London'
  var typeText = 'Property'
  var searcharea = ''
  var searchareaText=''
  var searchareaLink = ''
  var hasPrice = false;
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  if(props.Searcharea.price && (props.Searcharea.price.min !== "" || props.Searcharea.price.max !== "")) {
      hasPrice = true;
  }
  if(props.searchtype == 'sales') {
    searchaction = 'for Sale'
    URL          = '/property-for-sale/'
  }
  if(props.searchtype == 'rentals') {
    searchaction = 'to Rent'
    URL          = '/property-to-rent/'
  }
  if(props.searchtype == 'newhomesales') {
    searchaction = 'for Sale'
    URL          = '/property/new-homes/for-sale/'
    typeText = 'New Property'
  }
  if(props.searchtype == 'newhomerentals') {
    searchaction = 'to Rent'
    URL          = '/property/new-homes/to-rent/'
    typeText = 'New Property'
  }
  if(props.searchPtype) {
    let tmpType = get(buildingTypes, props.searchPtype, props.searchPtype)
    ptypeURL   = '/type-'+props.searchPtype?.toLowerCase()?.replace(/\s/g , '-')
    typeText     = capitalizeFirstLetter(tmpType).replace("Buy-to-let-investments", "Buy to let investments")
  }
  if(props.searchBedroomfield) {
    bedtypeText = props.searchBedroomfield+' Bedroom '+typeText
    bedURL   = '/'+props.searchBedroomfield+'-and-more-bedrooms'
  }
  //console.log("$$$$", props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas)
  if(props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas) {
    searcharea = props.Searcharea.areas
    searchareaText = capitalizeFirstLetter(props.Searcharea.areas.replace(/%20/g, " "))
    searchareaLink = searchareaText?.toLowerCase()?.replace(/\s/g , '-')
  }
  return (<React.Fragment>
        <Row>
          {props.searchBedroomfield ?
          <Col md={6} lg={3} className="properties-info">
          <ul>
            <li><a className="footer_scroll_top" href={`${URL}in-norton${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Norton</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-woodseats${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Woodseats</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-sheffield${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Sheffield</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-chesterfield${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Chesterfield</a></li>
          </ul>
          </Col>
          :<Col md={6} lg={3}  className="properties-info">
          <ul>
            <li><a className="footer_scroll_top" href={`${URL}in-norton${ptypeURL}/`}>{typeText} {searchaction} in Norton</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-woodseats${ptypeURL}/`}>{typeText} {searchaction} in Woodseats</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-sheffield${ptypeURL}/`}>{typeText} {searchaction} in Sheffield</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-chesterfield${ptypeURL}/`}>{typeText} {searchaction} in Chesterfield</a></li>
          </ul>
          </Col>
          }

          {searcharea ?
          <Col md={6} lg={3} className="properties-info">
            {(props?.searchPtype && !props?.searchBedroomfield) ?
            <ul>
                <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}${ptypeURL}/1-and-more-bedrooms/`}>1 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}${ptypeURL}/2-and-more-bedrooms/`}>2 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}${ptypeURL}/3-and-more-bedrooms/`}>3 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}${ptypeURL}/4-and-more-bedrooms/`}>4 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</a></li>
            </ul> :
            (props?.searchBedroomfield && hasPrice) ?
            <ul>
                <li><a className="footer_scroll_top" href={`${URL}in-norton/`}>Property {searchaction} in Norton</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-woodseats/`}>Property {searchaction} in Woodseats</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-sheffield/`}>Property {searchaction} in Sheffield</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-chesterfield/`}>Property {searchaction} in Chesterfield</a></li>
            </ul> : (props?.searchBedroomfield && props?.searchPtype) ?
            <ul>
                <li><a className="footer_scroll_top" href={`${URL}in-norton${ptypeURL}`}>{typeText} {searchaction} in Norton</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-woodseats${ptypeURL}`}>{typeText} {searchaction} in Woodseats</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-sheffield${ptypeURL}`}>{typeText} {searchaction} in Sheffield</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-chesterfield${ptypeURL}`}>{typeText} {searchaction} in Chesterfield</a></li>
            </ul>
            : props?.searchBedroomfield ?
            <ul>
                <li><a className="footer_scroll_top" href={`${URL}in-norton/type-apartment/`}>Apartments {searchaction} in Norton</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-woodseats/type-flat/`}>Flats {searchaction} in Woodseats</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-sheffield/type-detached-house/`}>Houses {searchaction} in Sheffield</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-chesterfield/type-bungalows/`}>Bungalows {searchaction} in Chesterfield</a></li>
            </ul> :
            <ul>
                <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/type-apartment/`}>Apartments {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/type-flat/`}>Flats {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/type-detached-house/`}>Houses {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/type-bungalows/`}>Bungalows {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            </ul>
            }
          </Col>:
          <Col md={6} lg={3} className="properties-info">
          <ul>
            <li><a className="footer_scroll_top" href={`${URL}in-sheffield/type-bungalow/`}>Bungalows {searchaction} in {area}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-sheffield/type-apartment/`}>Apartments {searchaction} in {area}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-sheffield/type-detached-house/`}>Houses {searchaction} in {area}</a></li>
          </ul>
          </Col>
          }


          {searcharea ?
          <Col md={6} lg={3} className="properties-info">
            {props.searchBedroomfield || props.searchPtype ?
            <ul>
            {/* {props.searchPtype &&
                <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/`}>Property {searchaction} in {searchareaText}</a></li>
            } */}

            <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/type-apartment/`}>Apartments {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/type-flat/`}>Flats {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/type-detached-house/`}>Houses {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/type-bungalows/`}>Bungalows {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            </ul> :
            <ul>
            <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/1-and-more-bedrooms/`}>1 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/2-and-more-bedrooms/`}>2 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/3-and-more-bedrooms/`}>3 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-${searchareaLink}/4-and-more-bedrooms/`}>4 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            </ul>
            }
          </Col>:
          <Col md={6} lg={3} className="properties-info">
          <ul>
            <li><a className="footer_scroll_top" href={`${URL}in-sheffield/type-apartment/`}>Apartments {searchaction} in {area}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-sheffield/type-flat/`}>Flats {searchaction} in {area}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-sheffield/type-detached-house/`}>Houses {searchaction} in {area}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-sheffield/type-bungalow/`}>Bungalows {searchaction} in {area}</a></li>
          </ul>
          </Col>
          }

          <Col md={6} lg={3} className="properties-info">
          <ul>
            <li><a className="footer_scroll_top" href={`${AREA_GUIDES_PAGE_URL.alias}/`}>{capitalizeFirstLetter(searchareaText)} Area Guides</a></li>
            <li><a className="footer_scroll_top" href={`${CONTACT_PAGE_URL.alias}/`}>Estate Agents in {capitalizeFirstLetter(searchareaText)}</a></li>
            <li><a className="footer_scroll_top" href={`${BOOK_A_VALUATION_PAGE_URL.alias}/`}>Property Valuation</a></li>
            <li><a className="footer_scroll_top" href={`${ABOUT_PAGE_URL.alias}/`}>About Redbrik Estate Agents</a></li>
          </ul>
          </Col>
        </Row>



  </React.Fragment>)

}

export default PopularSearchDynamic;

import React, { useState, useEffect } from "react";
import { defaultArea } from "@constants";
import { Link } from "@StarberryUtils";
import { Row, Col } from 'react-bootstrap';
import $ from 'jquery'

import { BOOK_A_VALUATION_PAGE_URL, CONTACT_PAGE_URL, ABOUT_PAGE_URL, PROPERTY_SERVICES_PAGE_URL, AREA_GUIDES_PAGE_URL, LATEST_NEWS_PAGE_URL, MEET_THE_TEAM_PAGE_URL, NEW_HOMES_PAGE_URL, FOR_SALE_PAGE_URL, TO_RENT_PAGE_URL } from '../common/site/constants'

const PopularSearchDetails = (props) => {

        // var propertydetails = props.propertydetails

        var typeText = ''
        var URL = ''
        // var area = 'Sheffield'
        // var ptypeURL= '/type-'+propertydetails.building

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        return(
        <Row>

            <Col md={6} lg={3} className="properties-info">
            <ul>
            <li><a className="footer_scroll_top" href={`${FOR_SALE_PAGE_URL.alias}/in-${props.area_name?.toLowerCase()?.replace(/\s/g , '-')}/`}>Property for Sale in {props.area_name}</a></li>
            <li><a className="footer_scroll_top" href={`${FOR_SALE_PAGE_URL.alias}/in-${props.area_name?.toLowerCase()?.replace(/\s/g , '-')}/type-apartment/`}>Apartments for Sale in {props.area_name}</a></li>
            <li><a className="footer_scroll_top" href={`${FOR_SALE_PAGE_URL.alias}/in-${props.area_name?.toLowerCase()?.replace(/\s/g , '-')}/type-detached-house/`}>Houses for Sale in {props.area_name}</a></li>
            <li><a className="footer_scroll_top" href={`${FOR_SALE_PAGE_URL.alias}/in-${props.area_name?.toLowerCase()?.replace(/\s/g , '-')}/type-bungalow/`}>Bungalows for Sale in {props.area_name}</a></li>
            </ul>
            </Col>

            <Col md={6} lg={3} className="properties-info">
            <ul>
            <li><a className="footer_scroll_top" href={`${TO_RENT_PAGE_URL.alias}/in-${props.area_name?.toLowerCase()?.replace(/\s/g , '-')}/`}>Property to Rent in {props.area_name}</a></li>
            <li><a className="footer_scroll_top" href={`${TO_RENT_PAGE_URL.alias}/in-${props.area_name?.toLowerCase()?.replace(/\s/g , '-')}/type-apartment/`}>Apartments to Rent in {props.area_name}</a></li>
            <li><a className="footer_scroll_top" href={`${TO_RENT_PAGE_URL.alias}/in-${props.area_name?.toLowerCase()?.replace(/\s/g , '-')}/type-detached-house/`}>Houses to Rent in {props.area_name}</a></li>
            <li><a className="footer_scroll_top" href={`${TO_RENT_PAGE_URL.alias}/in-${props.area_name?.toLowerCase()?.replace(/\s/g , '-')}/type-bungalow/`}>Bungalows to Rent in {props.area_name}</a></li>
            </ul>
            </Col>


            <Col md={6} lg={3} className="properties-info">
            <ul>
            <li><Link className="footer_scroll_top" to={`${ABOUT_PAGE_URL.alias}`}>About Redbrik Estate Agents</Link></li>
            <li><Link className="footer_scroll_top" to={`${CONTACT_PAGE_URL.alias}`}>Estate Agents in Chesterfield</Link></li>
            <li><Link className="footer_scroll_top" to={`${CONTACT_PAGE_URL.alias}`}>Estate Agents in Sheffield</Link></li>
            <li><Link className="footer_scroll_top" to={`${LATEST_NEWS_PAGE_URL.alias}`}>Property News</Link></li>
            </ul>
            </Col>

          <Col md={6} lg={3} className="properties-info">
          <ul>
            <li><Link className="footer_scroll_top" to={`${MEET_THE_TEAM_PAGE_URL.alias}`}>Meet our Team</Link></li>
            <li><Link className="footer_scroll_top" to={`${NEW_HOMES_PAGE_URL.alias}`}>New Homes in Sheffield & Chesterfield</Link></li>
            <li><Link className="footer_scroll_top" to={`${PROPERTY_SERVICES_PAGE_URL.alias}`}>Property Services Sheffield & Chesterfield</Link></li>
            <li><Link className="footer_scroll_top" to={`${BOOK_A_VALUATION_PAGE_URL.alias}`}>Property Valuation</Link></li>
          </ul>
          </Col>
        </Row>
    )

}

export default PopularSearchDetails;

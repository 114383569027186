import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { Link } from "@StarberryUtils";
import { defaultArea } from "@constants";
import SearchResultInputBox from "../predictive-search/SearchResultBox"
import GlobalContext from '../../context/GlobalContext';

import $ from "jquery";
import { navigate } from "@reach/router"

const SearchForm = (props) => {

    const [areaVal, setAreaVal] = useState('');

    useEffect(() => {
        let url = "/";

        $(".sales_btn").click(function(e) {
            e.preventDefault();
            var searcValue = $(".header_search_form .react-autosuggest__container input").val().trim().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").toLowerCase();
            if(searcValue !== "") {
                url = "in-"+searcValue+"/"
            } else {
                url = `in-${defaultArea.slug}/`
            }
            navigate('/property-for-sale/'+url);
            if(props.page == 'property-for-sale') {
                window.location.reload();
            }
        })

        $(".rent_btn").click(function(e) {
            e.preventDefault();
            console.log(areaVal);
            var searcValue = $(".header_search_form .react-autosuggest__container input").val().trim().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").toLowerCase();
            if(searcValue !== "") {
                url = "in-"+searcValue+"/"
            } else {
                url = `in-${defaultArea.slug}`
            }
            navigate('/property-to-rent/'+url);
            if(props.page == 'property-to-rent') {
                window.location.reload();
            }
        })
    },[]);

    return (
        <Formik
            initialValues={{ search: '' }}
            validate={values => {
                const errors = {};
                if (!values.search) {
                errors.search = 'Required';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit} className="header_search_form">
                    <SearchResultInputBox
                      value={''}
                      setValue={setAreaVal}
                      placeHolder={`Location`}
                    />
                    <div className="btns header-search-form-btn">
                        <button type="submit" className="sales_btn btn">Buy</button>
                        <button type="submit" className="rent_btn btn">Rent</button>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default SearchForm

import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { defaultArea } from "@constants";
import { Row, Col } from 'react-bootstrap';
import $ from 'jquery'

import { BOOK_A_VALUATION_PAGE_URL, CONTACT_PAGE_URL, ABOUT_PAGE_URL, PROPERTY_SERVICES_PAGE_URL, AREA_GUIDES_PAGE_URL} from '../common/site/constants'

const PopularSearchDetails = (props) => {

        var propertydetails = props.propertydetails

        var typeText = ''
        var URL = ''
        var area = defaultArea.name
        const town = (propertydetails?.address?.town || propertydetails?.address?.line_3 || propertydetails?.town || propertydetails?.area || '').trim()

        // var ptypeURL= '/type-'+propertydetails.building

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        if(propertydetails.search_type == 'sales') {
            typeText= 'for Sale'
            URL          = '/property-for-sale/'
            if(props.pagetype == 'newhomes') {
                URL = '/new-homes/current-developments/'
            }
            if(props.pagetype == 'commercial') {
                URL = '/property/commercial/for-sale/'
            }
        }
        if(propertydetails.search_type == 'lettings') {
            typeText= 'to Rent'
            URL          = '/property-to-rent/'
            if(props.pagetype == 'newhomes') {
                URL = '/new-homes/current-developments/'
            }
            if(props.pagetype == 'commercial') {
                URL = '/property/commercial/for-sale/'
            }
        }
        if(props?.department === 'newhomes') {
            typeText= 'for Sale'
            URL          = '/new-homes/current-developments/'
        }

        return(
        <Row>
            <Col md={6} lg={3} className="properties-info">
            <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-norton`}>{props.pagetype == 'newhomes' ? 'New ' : ''}{propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in Norton</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-woodseats`}>{props.pagetype == 'newhomes' ? 'New ' : ''}{propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in Woodseats</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-sheffield`}>{props.pagetype == 'newhomes' ? 'New ' : ''}{propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in Sheffield</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-chesterfield`}>{props.pagetype == 'newhomes' ? 'New ' : ''}{propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in Chesterfield</Link></li>
            </ul>
            </Col>



            {props.pagetype != "commercial" &&
            <Col md={6} lg={3} className="properties-info">
            <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-${town}/type-apartment`}>Apartments {typeText} in {town}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${town}/type-flat`}>Flats {typeText} in {town}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${town}/type-detached-house`}>Houses {typeText} in {town}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${town}/type-bungalow`}>Bungalows {typeText} in {town}</Link></li>
            </ul>
            </Col>
            }

            {props.pagetype != "commercial" &&
            <Col md={6} lg={3} className="properties-info">
            <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-${town}/1-and-more-bedrooms`}>1 Bedroom {propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in {town}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${town}/2-and-more-bedrooms`}>2 Bedroom {propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in {town}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${town}/3-and-more-bedrooms`}>3 Bedroom {propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in {town}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${town}/4-and-more-bedrooms`}>4 Bedroom {propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in {town}</Link></li>
            </ul>
            </Col>
            }

          <Col md={6} lg={3} className="properties-info">
          <ul>
            <li><Link className="footer_scroll_top" to={`${AREA_GUIDES_PAGE_URL.alias}`}>{capitalizeFirstLetter(area)} Area Guides</Link></li>
            <li><Link className="footer_scroll_top" to={`${CONTACT_PAGE_URL.alias}`}>Estate Agents in {capitalizeFirstLetter(area)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${BOOK_A_VALUATION_PAGE_URL.alias}`}>Property Valuation {capitalizeFirstLetter(area)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${ABOUT_PAGE_URL.alias}`}>About Redbrik Estate Agents</Link></li>
          </ul>
          </Col>
        </Row>
    )

}

export default PopularSearchDetails;
